import { render, staticRenderFns } from "./cabinet-lobby.html?vue&type=template&id=3ef4b08a&scoped=true&"
import script from "./cabinet-lobby.vue?vue&type=script&lang=ts&"
export * from "./cabinet-lobby.vue?vue&type=script&lang=ts&"
import style0 from "./cabinet-lobby.scss?vue&type=style&index=0&id=3ef4b08a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ef4b08a",
  null
  
)

export default component.exports