


import {Vue, Component, Watch} from 'vue-property-decorator';
import {Getter} from 'vuex-class';
import {Validations} from 'vuelidate-property-decorators';
import {TVuelidateRuleSet} from '@/_types/vuelitation-rule-set.type';
import IconSquareEdit from '@/_modules/icons/components/icon-square-edit.vue';
import IconSquareDelete from '@/_modules/icons/components/icon-square-delete.vue';
import IconPlus from '@/_modules/icons/components/icon-plus.vue';
import FormFileUploader from '@/_modules/controls/components/form-file-uploader/form-file-uploader.vue';
import {TFile} from '@/_types/file.type';
import {TLivePage, TLivePageGetRequest, TSponsor} from '@/_types/promo-page/live-page.type';
import ValidationHelper from '@/_helpers/validation.helper';
import {TranslateResult} from 'vue-i18n';
import FileHelper from '@/_helpers/file.helper';
import {maxLength} from 'vuelidate/lib/validators';
import draggable from 'vuedraggable';
import { TDragOptions } from '@/_types/drag-options.type';
import ErrorInfo from '@/_modules/error-info/error-info.vue';
import ApiErrorResponseData from '@/_types/api/api-error-response-data.class';

@Component({
  components: {
    IconSquareEdit,
    IconSquareDelete,
    IconPlus,
    FormFileUploader,
    draggable,
    ErrorInfo
  },
})

export default class CabinetLobby extends Vue {

  @Getter('cabinetLobbyStore/livePageError') livePageError: ApiErrorResponseData;

  @Validations()
  public readonly validations: TVuelidateRuleSet<TLivePage> | any = {
    livePageData: {
      agenda_video_player_frame: {
        agendaVideoPlayerFrame: ValidationHelper.isValidVideoStreamEmbed
      },
      video_player_frame: {videoPlayerFrame: ValidationHelper.isValidVideoStreamEmbed},
      sponsors: {
        $each: {
          link: {
            link: ValidationHelper.isValidUrl,
            maxLength: maxLength(250),
          }
        }
      }
    },
  }

  public photos: TFile[] = [];
  public sponsors: TSponsor[] = [];
  public isSendingSuccessful: boolean = false;
  public isSaveButtonDisabled: boolean = false;
  public updatedHintText: TranslateResult = '';
  public agendaVideoUrlError: string = ''
  public videoUrlError: string = ''
  // public photosError: string = ''
  public sponsorsError: string = ''
  public isAgendaVideoUrlLoading: boolean = false
  public isVideoUrlLoading: boolean = false
  public isPhotoLoading: boolean = false
  public isSponsorsLoading: boolean = false
  public sponsorLink: string = ''
  public sponsorIds: number[] = []
  public introDescriptionMaxLength: number = 65000;
  public agendaDescriptionMaxLength: number = 65000;

  public isDragInProgress: boolean = false;
  public dragOptions: TDragOptions = {
    animation: 200,
    group: 'sponsors',
    disabled: false,
  };

  private onDragStart(): void {
    this.isDragInProgress = true;
  }

  private onDragEnd(): void {
    this.isDragInProgress = false;

    this.livePageData.sponsors = this.sponsors.map((item) => {
      return Object.assign({}, {
        url: item.url,
        link: item.link || '',
        id: item.id || null
      });
    });
  }

  public livePageData: TLivePageGetRequest = {
    title: '',
    descr: '',
    video_player_frame: '',
    video_file_url: null,
    agendas: '',
    agenda_video_player_frame: '',
    agenda_video_file_url: null,
    // photos: [],
    sponsors: [],
    show_title: true,
    show_event_program: true,
    show_sponsors: true,
    show_event_news: true,
    show_event_announcements: true,
    show_streaming: true,
    use_program_sessions: true
  };

  public get eventId(): number {
    return this.$route.params.eventId ? parseInt(this.$route.params.eventId, 10) : null;
  }

  public get showAgendaVideoPlayerFrame(): boolean {
    return !(this.livePageData.agenda_video_file_url && this.livePageData.agenda_video_file_url.url);
  }

  public get showAgendaVideoPlayer(): boolean {
    return !this.livePageData.agenda_video_player_frame;
  }

  public get showVideoPlayerFrame(): boolean {
    return !(this.livePageData.video_file_url && this.livePageData.video_file_url.url);
  }

  public get showVideoPlayer(): boolean {
    return !this.livePageData.video_player_frame;
  }

  @Watch('eventId', {immediate: true})
  private onEventIdChange(): void {
    this.setLocalLivePageData();
  }

  @Watch('photos')
  private onPhotosChange(): void {
    this.isPhotoLoading = false;
    this.livePageData.photos = this.photos;
  }

  @Watch('sponsors')
  private onSponsorsChange(): void {
    this.isSponsorsLoading = false;
    this.livePageData.sponsors = this.sponsors.map((item, index) => {
      return Object.assign({}, {
        url: item.url,
        link: (this.livePageData.sponsors[index] && (this.livePageData.sponsors[index] as TSponsor).link) || '',
        id: (this.livePageData.sponsors[index] && this.livePageData.sponsors[index].id) || null
      });
    });
    if (this.sponsorLink) {
      (this.livePageData.sponsors[this.livePageData.sponsors.length - 1] as TSponsor).link = this.sponsorLink;
      this.sponsorLink = '';
    }
  }

  @Watch('livePageData.agenda_video_file_url.url', {immediate: true})
  private onAgendaVideoUrlLoadingChange(): void {
    if ((this.livePageData.agenda_video_file_url && this.livePageData.agenda_video_file_url.url) || this.agendaVideoUrlError) {
      this.isAgendaVideoUrlLoading = false;
    }
  }

  @Watch('livePageData.video_file_url.url', {immediate: true})
  private onVideoUrlLoadingChange(): void {
    if ((this.livePageData.video_file_url && this.livePageData.video_file_url.url) || this.videoUrlError) {
      this.isVideoUrlLoading = false;
    }
  }

  public async setLocalLivePageData(): Promise<void> {
    if (!this.eventId) {
      return;
    }
    const result: TLivePage = await this.$store.dispatch('cabinetLobbyStore/getLivePage', this.eventId);

    if (result) {
      this.livePageData.title = result.title;
      this.livePageData.descr = result.descr;
      this.livePageData.video_player_frame = result.video_player_frame;
      this.livePageData.video_file_url = Object.assign({}, {
        url: result.video_file_url || ''
      });
      this.livePageData.agendas = result.agendas;
      this.livePageData.agenda_video_player_frame = result.agenda_video_player_frame;
      this.livePageData.agenda_video_file_url = Object.assign({}, {
        url: result.agenda_video_file_url || ''
      });
      this.livePageData.photos = (result.photos || []).map(item => {
        return Object.assign({}, item, {
          url: item
        });
      });
      this.livePageData.sponsors = (result.sponsors || []).map((item, index) => {
        return Object.assign({}, {
          url: item.photo_url,
          link: item.link,
          id: item.id,
          sorting: index
        });
      });
      this.livePageData.show_title = result.show_title;
      this.livePageData.show_event_program = result.show_event_program;
      this.livePageData.show_sponsors = result.show_sponsors;
      this.livePageData.show_event_news = result.show_event_news;
      this.livePageData.show_event_announcements = result.show_event_announcements;
      this.livePageData.show_streaming = result.show_streaming;
      this.livePageData.use_program_sessions = result.use_program_sessions;

      this.photos = this.livePageData.photos;
      (this.sponsors as TFile[]) = this.livePageData.sponsors;

      this.sponsors = this.sponsors.sort((a, b) => {
        if (a.sorting < b.sorting) {
          return -1;
        } else if (a.sorting > b.sorting) {
          return 1;
        }
        return 0;
      });
    }
  }

  private rewind(targetRefName: string): void {
    const PERCENTAGE = 0.25;

    if (this.$refs[targetRefName] && PERCENTAGE) {
      try {
        const vid = (this.$refs[targetRefName] as HTMLVideoElement);
        vid.currentTime = vid.duration * PERCENTAGE;
      } catch (e) {
      }
    }

  }

  private async deleteSponsor(index: number): Promise<void> {
    if (!this.livePageData.sponsors[index]) {
      return;
    }
    const removedUrl = this.livePageData.sponsors[index].url;
    if(this.livePageData.sponsors[index].id) {
      await this.$store.dispatch('cabinetLobbyStore/deleteSponsor', {
        eventId: this.eventId,
        sponsor: this.livePageData.sponsors[index].id
      });
    }
    this.livePageData.sponsors.splice(index, 1);
    this.sponsors.forEach((item, index) => {
      if (item.url === removedUrl) {
        this.sponsors.splice(index, 1);
      }
    });
    await this.$store.dispatch('cabinetLobbyStore/refresh');
  }

  private deleteIntroVideoFileUrl(): void {
    if (!this.livePageData.video_file_url) {
      return;
    }
    this.livePageData.video_file_url = null;
  }

  private deleteAgendaVideoFileUrl(): void {
    if (!this.livePageData.agenda_video_file_url) {
      return;
    }
    this.livePageData.agenda_video_file_url = null;
  }

  public async saveLobbyPage(): Promise<void> {
    this.$v.livePageData.$touch();
    if (!this.livePageData || this.$v.livePageData.$pending || this.$v.livePageData.$invalid) {
      return;
    }
    this.isSaveButtonDisabled = true;

    const payload = {
      eventId: this.eventId,
      livePageData: Object.assign({}, this.livePageData, {
        photos: this.livePageData.photos.map(file => file.url),
        video_file_url: (this.livePageData.video_file_url && this.livePageData.video_file_url.url) || '',
        agenda_video_file_url: (this.livePageData.agenda_video_file_url && this.livePageData.agenda_video_file_url.url) || ''
      }),
    };

    await Promise.all(this.sponsorIds.map(async (id) => {
      await this.$store.dispatch('cabinetLobbyStore/deleteSponsor', {
        eventId: this.eventId,
        sponsor: id
      });
    }));

    const sponsorsPayload = {
      sponsorsRaw: this.livePageData.sponsors.map((file, index) => {
        if (file.id) {
          this.sponsorIds.push(file.id);
        }

        return {
          sponsor: {
            photo_url: file.url,
            link: (file as TSponsor).link,
            sorting: (file as TSponsor).sorting || index,
          },
          id: file.id
        };
      })
    };

    const result = await this.$store.dispatch('cabinetLobbyStore/saveLivePage', payload);

    const sponsorsResult = await Promise.all(sponsorsPayload.sponsorsRaw.map(async (content) => {

      if (content && content.id) {
        await this.$store.dispatch('cabinetLobbyStore/deleteSponsor', {
          eventId: this.eventId,
          sponsor: content.id
        });
      }

      await this.$store.dispatch('cabinetLobbyStore/saveSponsor', {
        eventId: this.eventId,
        sponsor: content.sponsor
      });
    }));

    if (result && sponsorsResult) {
      this.$v.$reset();
      this.isSendingSuccessful = true;
      await this.$store.dispatch('cabinetLobbyStore/refresh');
      this.updatedHintText = this.$t('eventPage.edit.updatedSuccess');
    } else {
      this.isSendingSuccessful = false;
      this.updatedHintText = this.$t('eventPage.edit.updatedError');
    }

    await this.setLocalLivePageData();

    setTimeout(() => {
      this.updatedHintText = '';
      this.isSaveButtonDisabled = false;
    }, 3000);

  }

  private getEventsWalletBroadcastYoutubeURL(): string {
    const lang: string = this.$route.params.lang;
    switch (lang) {
      case 'ru':
        return '/documents/Instructions for setting up a Youtube video broadcast_RU.pdf';
      case 'uk':
        return '/documents/Instructions for setting up a Youtube video broadcast_UKR.pdf';
      default:
        return '/documents/Instructions for setting up a Youtube video broadcast_EN.pdf';
    }
  }

  private getEventsWalletEmbedStreamingYoutubeURL(): string {
    const lang: string = this.$route.params.lang;
    switch (lang) {
      case 'ru':
        return '/documents/Instructions for setting up live streaming from Youtube_RU.pdf';
      case 'uk':
        return '/documents/Instructions for setting up live streaming from Youtube_UKR.pdf';
      default:
        return '/documents/Instructions for setting up live streaming from Youtube_EN.pdf';
    }
  }

  public setAndDisplayAgendaVideoFileError(errorText: string): void {
    this.agendaVideoUrlError = errorText || '';

    setTimeout(() => {
      this.agendaVideoUrlError = '';
      this.isAgendaVideoUrlLoading = false;
    }, 3000);
  }

  public setAndDisplayIntroVideoFileError(errorText: string): void {
    this.videoUrlError = errorText || '';

    setTimeout(() => {
      this.videoUrlError = '';
      this.isVideoUrlLoading = false;
    }, 3000);
  }

  public setAndDisplaySponsorsError(errorText: string): void {
    this.sponsorsError = errorText || '';
    setTimeout(() => {
      this.sponsorsError = '';
      this.isSponsorsLoading = false;
    }, 3000);
  }

  public setIsAgendaVideoUrlLoading(isLoading: boolean): void {
    this.isAgendaVideoUrlLoading = isLoading;
  }

  public setIsVideoUrlLoading(isLoading: boolean): void {
    this.isVideoUrlLoading = isLoading;
  }

  public setIsSponsorsLoading(isLoading: boolean): void {
    this.isSponsorsLoading = isLoading;
  }

  public get youtubeEmbedInstructionsURL(): string {
    return 'https://support.google.com/youtube/answer/171780?hl=' + this.$i18n.locale;
  }

  public get isIntroFileAVideo(): boolean {
    return FileHelper.isVideo(this.livePageData.video_file_url.url);
  }

  public get isAgendaFileAVideo(): boolean {
    return FileHelper.isVideo(this.livePageData.agenda_video_file_url.url);
  }

  private preview(): void {
    const routeData = this.$router.resolve({name: 'promo-live', params: { eventId: this.$route.params.eventId }});
    window.open(routeData.href, '_blank');
  }

}
